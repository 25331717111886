<template>
  <b-container class="add-budget-wizard bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        <h1 class="text-left">{{ $t(translationPath + 'title') }}{{ formData.account ? ': "' + formData.account.title + '" ' + $t(translationPath + 'for_year') : '' }} {{ formData.year ? (formData.account ? formData.year : ': ' + formData.year) : '' }}</h1>
        <div class="text-regular col-md-8 px-0" v-html="$t(translationPath + 'intro_p1')"></div>

        <div class="step-container mt-4">
          <div class="stepper bg-light mx-auto text-regular">
            <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#525670'"  :errorColor="'#525670'" ref="step">
              <tab-content :title="$t(translationPath + 'tab_title')" :before-change="()=>validate()">
                <b-container class="add-budget-wizard-step add-budget-wizard-step01 px-0">
                  <b-row no-gutters>
                    <b-col cols="12" class="detail-form text-left py-2 px-0" align-self="center">
                      <b-form-group
                        label-class="pr-4 text-black"
                        label-for="budget"
                        label-cols-lg="3"
                        label-align-lg="right"
                        required
                        class="pt-0 pt-md-4"
                        :state="$v.formData.budgetId.$anyError ? !$v.formData.budgetId.$error : null"
                        :invalid-feedback="invalidBudgetIdFeedback"
                      >
                        <template slot="label">{{ $t(translationPath + 'budget_label') }}</template>
                        <b-form-select
                          id="budget"
                          :options="budgetsOptions"
                          v-model="formData.budgetId"
                          :class="{'is-valid': $v.formData.budgetId.$dirty && $v.formData.budgetId.$anyError === false, 'is-invalid': $v.formData.budgetId.$dirty && $v.formData.budgetId.$anyError === true }"
                          @input="onAccountChange"
                        />
                      </b-form-group>

                      <b-form-group
                        label-class="pr-4 text-black"
                        label-for="account"
                        label-cols-lg="3"
                        label-align-lg="right"
                        required
                        class="pt-0 pt-md-4"
                        :state="$v.formData.account.$anyError ? !$v.formData.account.$error : null"
                        :invalid-feedback="invalidAccountFeedback"
                      >
                        <template slot="label">{{ $t(translationPath + 'account_label') }}</template>
                        <custom-select
                          id="account"
                          v-model="formData.account"
                          :options="translatedAccount"
                          grouping-values="accounts"
                          grouping-label="title"
                          :sortGroupLabel="false"
                          :loading="isAccountsLoading"
                          label="title"
                          @input="onAccountChange"
                          :placeholder="$t('common.select_account')"
                          :noResultText="$t('common.no_account_found')"
                          v-bind:class="{'is-valid': $v.formData.account.$dirty && $v.formData.account.$anyError === false, 'is-invalid': $v.formData.account.$dirty && $v.formData.account.$anyError === true }"
                          :search-placeholder="$t('common.type_to_filter')"
                        >
                        </custom-select>
                      </b-form-group>
                      <template v-if="formData.account && formData.budgetId">

                      <b-tabs class="white-tabs" @input="onTabInput">
                        <b-tab :title="$t(translationPath + 'tab_title_amounts')">
                          <b-form-group
                            label-class="pr-4 text-black"
                            label-for="amount_action"
                            label-cols-lg="3"
                            label-align-lg="right"
                            required
                            class="pt-2 pt-md-4"
                          >
                            <template slot="label">{{ $t(translationPath + 'amount_action_label') }}</template>
                            <b-form-select
                              id="amount_action"
                              v-model="formData.amountAction"
                              :options="amountActionOptions"
                              @input="onAmountOrActionChange"
                            ></b-form-select>
                          </b-form-group>

                          <b-form-group
                            label-class="pr-4 text-black"
                            label-for="amount"
                            label-cols-lg="3"
                            label-align-lg="right"
                            required
                            class="pt-0 pt-md-1"
                          >
                            <template slot="label">{{ $t(translationPath + 'amount_label') }}</template>
                            <currency-input
                              ref="CurrencyInputAmount"
                              id="amount"
                              v-model="formData.amount"
                              @input="onAmountOrActionChange"
                            />
                          </b-form-group>
                          <hr />

                          <!-- MONTH VALUES -->
                          <b-row class="pl-3">
                            <b-col></b-col>
                            <b-col class="text-left pl-0">
                              <h4 class="mb-0 pb-0">
                                <label for="year">
                                  {{ $t(translationPath + 'set_budget_values') }}
                                </label>
                              </h4>
                              <p class="small" style="line-height: 120%">{{ $t(translationPath + 'set_budget_values_description') }}</p>
                            </b-col>
                            <b-col class="text-left">
                              <h4 class="mb-0 pb-0">
                                <label for="year_previous">
                                  {{ $t(translationPath + 'year_previous_label') }}
                                </label>
                              </h4>
                              <p class="small" style="line-height: 120%">{{ $t(translationPath + 'year_previous_label_description') }}</p>
                            </b-col>
                            <b-col class="text-left">
                              <h4 class="mb-0 pb-0">
                                <label for="year_actual_values">
                                  {{ $t(translationPath + 'year_actual_values_label') }}
                                </label>
                              </h4>
                              <p class="small" style="line-height: 120%">{{ $t(translationPath + 'year_actual_values_label_description') }}</p>
                            </b-col>
                          </b-row>
                          <b-form-group
                            label-class="pr-4 text-black"
                            label-for="year"
                            label-cols-lg="3"
                            label-align-lg="right"
                            required
                            :state="$v.formData.year.$anyError ? !$v.formData.year.$error : null"
                            :invalid-feedback="invalidYearFeedback"
                          >
                            <template slot="label">{{ $t(translationPath + 'year_label') }}</template>
                            <b-row class="pl-3">
                              <b-col>
                                <budget-year-select
                                  :defaultYear="$route.params.year ? parseInt($route.params.year) : new Date().getFullYear()"
                                  :options-key="'years'"
                                  @input="onYearChange"
                                ></budget-year-select>
                              </b-col>
                              <b-col>
                                <b-form-select
                                  id="year_previous"
                                  v-model="formData.yearPrevious"
                                  xoptions="yearPreviousOptions"
                                  :options="yearApiData ? yearApiData.years_with_existing_budget_values : []"
                                  @input="onPreviousYearChange"
                                ></b-form-select>
                                <b-button class="mt-3 w-100" @click="onAmountButtonAction('copy_budget')">{{ $t(translationPath + 'copy_budget_amount')}}</b-button>
                              </b-col>
                              <b-col>
                                <b-form-select
                                  id="year_actual_values"
                                  v-model="formData.yearActualValues"
                                  xoptions="yearActualValuesOptions"
                                  :options="yearApiData ? yearApiData.years_with_actual_transaction_values : []"
                                  @input="onActualYearChange"
                                ></b-form-select>
                                <b-button class="mt-3 w-100" @click="onAmountButtonAction('copy_actual')">{{ $t(translationPath + 'copy_actual_amount')}}</b-button>
                              </b-col>
                            </b-row>
                          </b-form-group>

                          <b-form-group
                            v-for="n in Object.keys(monthsLabels)"
                            :key="n"
                            label-class="pr-4 text-black"
                            label-for="amount_jan"
                            label-cols-lg="3"
                            label-align-lg="right"
                            required
                            class="pt-2 pt-md-0"
                            :state="$v.formData.amounts[n].$anyError ? !$v.formData.amounts[n].$error : null"
                            :invalid-feedback="invalidAmountFeedback"
                          >
                            <template slot="label">{{ $t('common.months.' + monthsLabels[n].full) }}</template>
                            <b-row class="pl-3">
                              <b-col>
                                <currency-input
                                  ref="CurrencyInputMonthAmount"
                                  :id="'amount_' + monthsLabels[n].short"
                                  v-model="formData.amounts[n]"
                                  @input="rerenderCurrencyInputs"
                                />
                              </b-col>
                              <b-col>
                                <currency-input
                                  ref="CurrencyInputMonthAmountPrev"
                                  :id="'amount_prev_' + monthsLabels[n].short"
                                  v-model="formData.amountsPrevious[n]"
                                  :disabled="true"
                                />
                              </b-col>
                              <b-col>
                                <currency-input
                                  ref="CurrencyInputMonthActual"
                                  :id="'amount_actual_' + monthsLabels[n].short"
                                  v-model="formData.amountsActual[n]"
                                  :disabled="true"
                                />
                              </b-col>
                            </b-row>
                          </b-form-group>

                          <b-form-group
                            label-class="pr-4 text-black"
                            label-cols-lg="3"
                            label-align-lg="right"
                            required
                            class="pt-2 pt-md-4"
                          >
                            <template slot="label">{{ $t('common.total') }}</template>
                              <b-row class="pl-3">
                                <b-col>
                                  <currency-input
                                    ref="CurrencyInputTotal"
                                    id="amount_total"
                                    :value="totalAmount"
                                    :disabled="true"
                                  />
                                </b-col>
                                <b-col>
                                  <currency-input
                                    ref="CurrencyInputTotalPrev"
                                    id="amount_total_prev"
                                    v-model="totalAmountPrevious"
                                    :disabled="true"
                                  />
                                </b-col>
                                <b-col>
                                  <currency-input
                                    ref="CurrencyInputTotalActual"
                                    id="amount_total_actual"
                                    v-model="totalAmountActual"
                                    :disabled="true"
                                  />
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-tab>
                          <b-tab :title="$t(translationPath + 'tab_title_notes')">
                            <b-row no-gutters>
                              <b-col cols="8">
                                <div v-html="$t(translationPath + 'tab_notes_description')" class="pt-2 mb-3"></div>
                              </b-col>
                            </b-row>

                            <template v-if="!isNotesLoaded">
                              <loader />
                            </template>
                            <template v-else>
                              <b-form>
                                <b-form-group
                                  label-for="import_textarea"
                                  required
                                  label-class="pr-4 text-black px-2"
                                  :label="$t(translationPath + 'notes')"
                                >
                                  <b-form-textarea
                                    id="notes_textarea"
                                    v-model="notes"
                                    class="form-control text-left"
                                  />
                                </b-form-group>
                              </b-form>
                              <b-row no-gutters>
                                <b-col cols="3" class="text-left">
                                  <button class="btn btn-sm btn-primary" @click.prevent="updateNotes" prev>{{$t(translationPath + 'btn_update_notes')}}</button>
                                </b-col>
                              </b-row>
                            </template>
                          </b-tab>
                        </b-tabs>
                      </template>
                    </b-col>
                  </b-row>
                </b-container>
              </tab-content>
              <template slot="footer" slot-scope="props">
                <div :class="{ hasSpace: props.activeTabIndex !== 0 }">
                  <div class="wizard-footer-right">
                     <wizard-button v-if="tabIndex === 0" class="wizard-footer-right finish-button" @click.native="validate" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t(translationPath + 'btn_save_text') }}
                    </wizard-button>
                  </div>
                </div>
              </template>

              <success-modal
                :title="$t(translationPath+'success_modal.title')"
                :message="$t(translationPath+'success_modal.message',{account: (formData.account ? formData.account.title : ''), year: (formData.year ? formData.year : '')})"
                :button="$t(translationPath+'success_modal.button_text')"
                :redirect="'/budget/accounts/'+(formData.account ? formData.account.type : 'income')+'s'"
                :orLinkText="$t(translationPath+'success_modal.or_text')"
                :orLink="'/redirect?to=manageBudget'"
              ></success-modal>

            </form-wizard>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import SuccessModal from '@/components/modals/SuccessModal'
import CustomSelect from '@/components/common/CustomSelect'
import CurrencyInput from '@/components/common/CurrencyInput'
import BudgetYearSelect from '@/components/common/BudgetYearSelect'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import titleMixins from '@/mixins/title'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'

const AMOUNT_ACTION = {
  FILL: 1,
  DIVIDE: 2,
  COPY: 3,
  COPY_BUDGET: 4
}

export default {
  name: 'BudgetSingleAccount',
  mixins: [titleMixins, useComponentCurrencyInputMixin],
  components: {
    Loader,
    FormWizard,
    TabContent,
    WizardButton,
    SuccessModal,
    CustomSelect,
    CurrencyInput,
    BudgetYearSelect
  },
  validations: {
    formData: {
      budgetId: { required },
      account: { required },
      year: {
        required,
        minValue: minValue(2019),
        maxValue: maxValue(2200)
      },
      amounts: {
        1: { required },
        2: { required },
        3: { required },
        4: { required },
        5: { required },
        6: { required },
        7: { required },
        8: { required },
        9: { required },
        10: { required },
        11: { required },
        12: { required }
      }
    }
  },
  data () {
    return {
      translationPath: 'budget.single_account.',
      tabIndex: null,
      budgets: [],
      accountOptions: [],
      isAccountsLoading: false,
      yearApiData: null,
      amountsEmpty: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0
      },
      formData: {
        account: null,
        budgetId: null,
        year: new Date().getFullYear(),
        yearPrevious: '',
        yearActualValues: '',
        amount: 0,
        amountAction: AMOUNT_ACTION.DIVIDE,
        amounts: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0
        },
        amountsPrevious: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0
        },
        amountsActual: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0
        }
      },
      notes: '',
      isNotesLoaded: false,
      monthsLabels: {
        1: { short: 'jan', full: 'january' },
        2: { short: 'feb', full: 'february' },
        3: { short: 'mar', full: 'march' },
        4: { short: 'apr', full: 'april' },
        5: { short: 'may', full: 'may' },
        6: { short: 'jun', full: 'june' },
        7: { short: 'jul', full: 'july' },
        8: { short: 'aug', full: 'august' },
        9: { short: 'sep', full: 'september' },
        10: { short: 'oct', full: 'october' },
        11: { short: 'nov', full: 'november' },
        12: { short: 'dec', full: 'december' }
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.incomes_and_expenses'), to: '/transactions/view' },
        { text: this.$t('common.budget'), to: '/incomes-expenses/budget' },
        { text: '', active: true }
      ]
    },
    amountActionOptions () {
      return [
        { text: this.$t(this.translationPath + 'fill_all_month'), value: AMOUNT_ACTION.FILL },
        { text: this.$t(this.translationPath + 'divide_in_all_month'), value: AMOUNT_ACTION.DIVIDE },
        { text: this.$t(this.translationPath + 'copy'), value: AMOUNT_ACTION.COPY },
        { text: this.$t(this.translationPath + 'copy_budget'), value: AMOUNT_ACTION.COPY_BUDGET }
      ]
    },
    budgetsOptions () {
      if (!this.budgets) {
        return []
      }

      const opts = []
      this.budgets.map(budget => {
        opts.push({ text: budget.title, value: budget.id })
      })
      return opts
    },
    invalidAccountFeedback () {
      if (this.$v.formData.account.required === false) { return this.$t(this.translationPath + 'errors.required') }
      return ''
    },
    invalidBudgetIdFeedback () {
      if (this.$v.formData.budgetId.required === false) { return this.$t(this.translationPath + 'errors.required') }
      return ''
    },
    invalidYearFeedback () {
      if (this.$v.formData.year.required === false) { return this.$t(this.translationPath + 'errors.required') }
      if (this.$v.formData.year.minValue === false) { return this.$t(this.translationPath + 'errors.min_year') }
      if (this.$v.formData.year.maxValue === false) { return this.$t(this.translationPath + 'errors.max_year') }
      return ''
    },
    invalidAmountFeedback () {
      return this.$t(this.translationPath + 'errors.required')
    },
    translatedAccount () {
      return this.accountOptions.map(item => {
        return Object.assign(item, { title: (this.$te(this.translationPath + 'transaction_type.' + item.type.toLowerCase()) ? this.$t(this.translationPath + 'transaction_type.' + item.type.toLowerCase()) : item.type) + ' / ' + item.label })
      })
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    totalAmount () {
      let total = 0
      for (const i in this.formData.amounts) {
        total += this.formData.amounts[i]
      }
      return total
    },
    totalAmountPrevious () {
      let total = 0
      for (const i in this.formData.amountsPrevious) {
        total += this.formData.amountsPrevious[i]
      }
      return total
    },
    totalAmountActual () {
      let total = 0
      for (const i in this.formData.amountsActual) {
        total += this.formData.amountsActual[i]
      }
      return total
    }
  },
  methods: {
    loadBudget () {
      this.loadCurrentYearBudget()
      this.loadPreviousYearBudget()
    },
    async loadBudgets () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/budgets`)
          .then(response => {
            this.budgets = response.data.data
            resolve(this.budgets)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    async loadAccounts () {
      this.isAccountsLoading = true
      await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=income,expense&log=BudgetSingleAccount`)
        .then(response => {
          const optionArray = []
          const accountGroups = response.data.data
          Object.keys(accountGroups).forEach(function (key) {
            optionArray.push({ type: accountGroups[key].type, label: accountGroups[key].title, accounts: accountGroups[key].accounts })
          })
          this.accountOptions = optionArray
        })
        .catch(error => {
          console.error(error)
        })
      this.isAccountsLoading = false
    },
    async loadCurrentYearBudget () {
      if (!this.formData.account || !this.formData.year || this.formData.year === '' || !this.formData.budgetId) {
        this.formData.amounts = Object.assign({}, this.formData.amounts, this.amountsEmpty)
        return false
      }
      await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/${this.formData.year}/${this.formData.account.id}?budget_id=${this.formData.budgetId}`)
        .then((response) => {
          const set = response.data.data
          if (!set || set.length === 0) {
            this.formData.amounts = Object.assign({}, this.formData.amounts, this.amountsEmpty)
            return
          }
          for (const prop in set) {
            this.formData.amounts[set[prop].month] = parseFloat(set[prop].amount)
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.rerenderCurrencyInputs()
        })
    },
    async loadPreviousYearBudget () {
      if (!this.formData.account || !this.formData.yearPrevious || this.formData.yearPrevious === '' || !this.formData.budgetId) {
        this.formData.amountsPrevious = Object.assign({}, this.formData.amountsPrevious, this.amountsEmpty)
        return false
      }
      await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/${this.formData.yearPrevious}/${this.formData.account.id}?budget_id=${this.formData.budgetId}`)
        .then((response) => {
          const set = response.data.data
          if (!set || set.length === 0) {
            this.formData.amountsPrevious = Object.assign({}, this.formData.amountsPrevious, this.amountsEmpty)
            return
          }
          // reset
          for (const prop in this.formData.amountsPrevious) {
            this.formData.amountsPrevious[prop] = 0
          }

          // update
          for (const prop in set) {
            this.formData.amountsPrevious[set[prop].month] = parseFloat(set[prop].amount)
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.rerenderCurrencyInputs()
        })
    },
    async loadActualValues () {
      if (!this.formData.yearActualValues || !this.formData.account) {
        this.formData.amountsActual = this.amountsEmpty
        return false
      }
      const dateStart = this.formData.yearActualValues + '-01-01'
      const dateEnd = this.formData.yearActualValues + '-12-31'
      axios.get(`${process.env.VUE_APP_ROOT_API}/reports/account/${this.formData.account.id}/graphdata?start_date=${dateStart}&end_date=${dateEnd}`)
        .then(response => {
          const monthValues = response.data.data.graphData.months
          const actualAmountArray = []
          for (let i = 1; i <= 12; i++) {
            const dateIndex = this.formData.yearActualValues + '-' + (i >= 10 ? i : '0' + i)
            actualAmountArray[i] = monthValues[dateIndex] ? monthValues[dateIndex] : 0
          }
          this.formData.amountsActual = actualAmountArray
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.rerenderCurrencyInputs()
        })
    },
    async loadYearApiData () {
      return new Promise((resolve, reject) => {
        if (!this.formData.budgetId) {
          resolve(null)
        }

        let sApiUrl = `${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/years-for-component?budget_id=${this.formData.budgetId}`
        if (this.formData.account) {
          sApiUrl += `&account_id=${this.formData.account.id}`
        }
        axios.get(sApiUrl)
          .then(response => {
            this.yearApiData = response.data.data
            this.formData.yearPrevious = this.findNearestPreviousYear(this.yearApiData.years_with_existing_budget_values)
            this.formData.yearActualValues = this.findNearestPreviousYear(this.yearApiData.years_with_actual_transaction_values)
            resolve(this.yearApiData)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async loadNotes () {
      return new Promise((resolve, reject) => {
        if (!this.formData.budgetId || this.formData.account === null) {
          resolve(true)
        } else {
          this.isNotesLoaded = false

          const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-option/${this.formData.budgetId}/${this.formData.account.id}/simulation_notes`
          axios.get(apiEndpoint)
            .then(response => {
              if (response.data.data.simulation_notes) {
                this.notes = response.data.data.simulation_notes.value
              } else {
                this.notes = ''
              }
            })
            .finally(() => {
              this.isNotesLoaded = true
            })
        }
      })
    },
    async updateNotes () {
      return new Promise((resolve, reject) => {
        const apiEndpoint = `${process.env.VUE_APP_ROOT_API}/accounts/budget-option/${this.formData.budgetId}/${this.formData.account.id}/simulation_notes`
        axios.put(apiEndpoint, { option_value: this.notes })
          .then(response => {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.update_notes_success').toString(), {
              title: this.$t(this.translationPath + 'toast.update_notes_title').toString(),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
            resolve(true)
          })
          .catch(err => {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.update_notes_fail').toString(), {
              title: this.$t(this.translationPath + 'toast.update_notes_title').toString(),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            })
            reject(err)
          })
      })
    },
    onAmountOrActionChange () {
      let monthVal = null
      switch (this.formData.amountAction) {
        case AMOUNT_ACTION.FILL:
          for (const month in this.formData.amounts) {
            this.formData.amounts[month] = this.formData.amount
          }
          break
        case AMOUNT_ACTION.DIVIDE:
          monthVal = this.formData.amount / 12
          for (const month in this.formData.amounts) {
            this.formData.amounts[month] = monthVal
          }
          break
        case AMOUNT_ACTION.COPY:
          for (const prop in this.formData.amountsActual) {
            this.formData.amounts[prop] = this.formData.amountsActual[prop]
          }
          break
        case AMOUNT_ACTION.COPY_BUDGET:
          for (const prop in this.formData.amountsPrevious) {
            this.formData.amounts[prop] = this.formData.amountsPrevious[prop]
          }
      }
      this.rerenderCurrencyInputs('CurrencyInputAmount')
    },
    onAmountButtonAction (value) {
      switch (value) {
        case 'copy_actual':
          for (const prop in this.formData.amountsActual) {
            this.formData.amounts[prop] = this.formData.amountsActual[prop]
          }
          break
        case 'copy_budget':
          for (const prop in this.formData.amountsPrevious) {
            this.formData.amounts[prop] = this.formData.amountsPrevious[prop]
          }
      }
      this.rerenderCurrencyInputs()
    },
    async onAccountChange () {
      await this.loadYearApiData()
      this.loadBudget()
      this.loadNotes()
      this.rerenderCurrencyInputs()
    },
    onYearChange (year) {
      this.formData.year = year
      this.formData.yearPrevious = this.findNearestPreviousYear(this.yearApiData.years_with_existing_budget_values)
      this.formData.yearActualValues = this.findNearestPreviousYear(this.yearApiData.years_with_actual_transaction_values)
      this.loadBudget()
      this.rerenderCurrencyInputs()
    },
    onPreviousYearChange () {
      if (this.formData.yearPrevious) {
        this.loadPreviousYearBudget()
      } else {
        for (const i in this.formData.amountsPrevious) {
          this.formData.amountsPrevious[i] = 0
        }
      }
    },
    onActualYearChange () {
      if (this.formData.yearActualValues) {
        this.loadActualValues()
      } else {
        for (const i in this.formData.amountsActual) {
          this.formData.amountsActual[i] = 0
        }
      }
    },
    onHideSuccessModal () {
      this.$router.push('/redirect?to=manageBudget')
    },
    onTabInput (index) {
      this.tabIndex = index
    },
    findNearestPreviousYear (haystack) {
      if (!haystack.length || !this.formData.account) {
        return null
      }
      if (haystack.length === 1) {
        return haystack[0]
      }

      for (const i in haystack) {
        if (haystack[i] < this.formData.year) {
          continue
        }

        if (parseInt(i) === 0) {
          return haystack[0]
        }
        return haystack[(parseInt(i) - 1).toString()]
      }
      return haystack[haystack.length - 1]
    },
    validate () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.sendData().then(() => {
          this.$bvModal.show('successModal')
        })
        return true
      } else {
        this.$bvToast.toast(this.$t(this.translationPath + 'errors.any_error_msg'), {
          title: this.$t(this.translationPath + 'errors.any_error_title'),
          variant: 'danger',
          solid: true
        })
        return false
      }
    },
    async sendData () {
      const data = {
        account_id: this.formData.account.id,
        year: this.formData.year,
        budget_id: this.formData.budgetId,
        year_data: []
      }
      for (const prop in this.formData.amounts) {
        data.year_data.push({
          month: prop,
          amount: this.formData.amounts[prop]
        })
      }

      await axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/year`, data)
        .catch((error) => {
          console.error(error)
        })
    },
    preselectBudget () {
      if (this.$route.query.bid) {
        const bid = Number(this.$route.query.bid)
        const validBids = []
        this.budgets.map(budget => {
          validBids.push(budget.id)
        })
        if (validBids.indexOf(bid) !== -1) {
          this.formData.budgetId = Number(this.$route.query.bid)
        }
      }

      if (!this.formData.budgetId) {
        this.formData.budgetId = this.currentCOA.default_budget_id
      }
    }
  },
  async created () {
    await this.loadBudgets()
    this.preselectBudget()
    await this.loadAccounts()
    await this.loadYearApiData()

    if (this.$route.params.year) {
      this.formData.year = parseInt(this.$route.params.year)
    }
    if (this.$route.params.account_id) {
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/${this.$route.params.account_id}`)
        .then(async (response) => {
          this.formData.account = response.data.data
          await this.loadYearApiData()
          this.loadBudget()
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    'currentCOA.locale' () {
      if (!this.budgetId) {
        this.preselectBudget()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard.scss';

.add-budget-wizard {
  .wizard-progress-with-circle, .wizard-nav-pills {
    display: none;
  }
  .text-black {
    align-self: center;
  }
  .detail-form {
    border-left: none;
    @media screen and (max-width: 767.98px) {
      border-left: none;
    }
  }
  .wizard-navigation {
    margin-top: 0px;
  }
  .vue-form-wizard {
    background: #fff;
    border: 5px solid var(--secondary);
    border-radius: 30px;
  }
  .wizard-tab-content {
    padding-top: 0;
    padding-bottom: 10px;
    @media screen and (max-width:768px) {
      padding: 20px !important;
    }
  }

  textarea {
    height: 400px;
  }
}
</style>
