<template>
  <div class="budget-year-select">
    <b-form-select
      v-model="year"
      :options="options"
      @change="onYearChange"
      @input="onYearInput"
    ></b-form-select>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'BudgetYearSelect',
  props: {
    optionsKey: {
      type: String,
      default: 'years'
    },
    defaultYear: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data () {
    return {
      apiData: null,
      isLoaded: false,
      year: null
    }
  },
  created () {
    axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/budget-amounts/years-for-component`)
      .then(response => {
        this.apiData = response.data.data
        if (this.options.indexOf(this.defaultYear) !== -1) {
          this.year = this.defaultYear
        }
        this.isLoaded = true
        this.$emit('loaded')
      })
      .catch(error => {
        console.error(error)
      })
  },
  computed: {
    years () {
      let years = []
      if (this.apiData !== null && Object.prototype.hasOwnProperty.call(this.apiData, 'budget_period_normal_range')) {
        years = this.apiData.budget_period_normal_range
      }
      return years
    },
    yearsWithValues () {
      let years = []
      if (this.apiData !== null && Object.prototype.hasOwnProperty.call(this.apiData, 'years_with_existing_budget_values')) {
        years = this.apiData.years_with_existing_budget_values
      }
      return years
    },
    options () {
      return this[this.optionsKey] ? this[this.optionsKey] : []
    }
  },
  methods: {
    onYearChange (newVal) {
      this.$emit('change', newVal)
    },
    onYearInput (newVal) {
      this.$emit('input', newVal)
    },
    $setYear (year) {
      if (this.options.indexOf(year) !== -1) {
        this.year = year
      }
    }
  }
}
</script>

<style lang="scss">
.budget-year-select {

}
</style>
